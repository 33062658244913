<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :loading="pending.getTicketQueuePresets" :title="$t('Create Ticket Queue')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="name"
                      rules="required|max:50"
                      :counter="50"
                      label="Name"
                      placeholder="Enter Name"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VSelectWithValidation
                      v-model="user_id"
                      rules="required"
                      label="Moderator"
                      :items="usersArray"
                      menu-props="offset-y"
                      placeholder="Select Moderator"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VAutocompleteWithValidation
                      v-model="users"
                      multiple
                      label="Users"
                      :items="usersArray"
                      menu-props="offset-y"
                      placeholder="Select Users"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="pending.createTicketQueue"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import { parseObjectToArray } from '@/helpers'

import AppCard from '@/components/UI/AppCard.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation.vue'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation.vue'

export default defineComponent({
  name: 'CreateTicketQueue',
  components: {
    AppCard,
    ItemWrapper,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VAutocompleteWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/TicketQueues')

    // store
    const pending = computed(_ => $store.state.ticketQueue.pending)
    const ticketQueuePresets = computed(_ => $store.state.ticketQueue.ticketQueuePresets)
    const errors = computed(_ => $store.state.ticketQueue.errors)
    const createTicketQueue = _ => $store.dispatch('ticketQueue/createTicketQueue')
    const getTicketQueuePresets = _ => $store.dispatch('ticketQueue/getTicketQueuePresets')
    const clearTicketQueue = _ => $store.commit('ticketQueue/CLEAR_TICKET_QUEUE')

    const usersArray = computed(_ => parseObjectToArray(ticketQueuePresets.value.users))

    getTicketQueuePresets()

    const submitForm = _ => {
      if (pending.value.createTicketQueue) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createTicketQueue().then(({ ticketQueue, errors }) => {
        if (errors) {
          setForceRedirect(false)
          return
        }

        if (redirectDirect.value) {
          $router.push({
            name: 'ticketQueues-item',
            params: { id: ticketQueue.id },
          })
        } else {
          $router.push({ path: '/TicketQueues' })
        }
      })
    }

    onUnmounted(_ => {
      clearTicketQueue()
    })

    return {
      prevRoute,
      pending,
      usersArray,
      errors,
      submitForm,
      ...mapFields('ticketQueue', [
        'ticketQueue.name',
        'ticketQueue.user_id',
        'ticketQueue.users',
      ]),
    }
  },
}) //
</script>
